<template>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="1200px"
        transition="dialog-transition"
        scrollable
    >
        <v-card>
            <v-toolbar color="primary" class="subtitle white--text">
                {{titulo}}
                <v-spacer></v-spacer>
                <v-btn 
                    color="white"
                    text
                    icon
                    @click="$emit('cancelar')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="px-0" v-if="consultado">
                <v-stepper v-model="paso" vertical class="elevation-0">
                    <v-stepper-step :complete="paso > 0" step="0">
                        REQUISITOS PARA LA SOLICITUD
                    </v-stepper-step>
                    <v-stepper-content step="0" class="py-0 pl-0 auto-height">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-list two-line>
                                    <v-list-item v-for="(requisito,index) in requisitos" :key="index">
                                        <v-list-item-avatar>
                                            <v-icon
                                                class="grey lighten-1"
                                                dark
                                            >{{requisito.icon}}         
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="requisito.titulo"> 
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-html="requisito.subtitulo">
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>        
                                </v-list>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="$emit('cancelar')">
                                Cancelar
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="continuar"
                                >
                                    Aceptar y Continuar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :complete="paso > 1" step="1">
                        FORMULARIO GENERAL
                    </v-stepper-step>
                    <v-stepper-content step="1" class="py-0 auto-height">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-form v-model="valid" ref="formulario" lazy-validation>
                                    <v-row no-gutters>
                                        <v-col
                                            cols="4"
                                            lg="4"
                                            md="4"
                                            xs="4"
                                        >  <v-select
                                                class="required px-1"
                                                name="departamento"
                                                :items="this.$store.state.session.parametros.departamentos"
                                                v-model="sociedad.departamento"
                                                label="Departamento"
                                                :rules="getRules('requiredSelect')"
                                                filled
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                            cols="8"
                                            lg="8"
                                            md="8"
                                            xs="8"
                                        >
                                            <v-text-field
                                                v-model="sociedad.nombreSociedad"
                                                label="Razón Social"
                                                class="required px-1"
                                                :rules="getRules('required')"
                                                filled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="12"
                                            md="12"
                                            xs="12"
                                        >
                                            <v-text-field
                                                v-model="sociedad.direccion"
                                                label="Domicilio Procesal"
                                                class="required px-1"
                                                :rules="getRules('required')"
                                                filled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            lg="4"
                                            md="4"
                                            xs="12"
                                        >
                                            <v-text-field
                                                v-model="sociedad.telefonos"
                                                label="Telefono(s)"
                                                class="required px-1"
                                                :rules="getRules('required')"
                                                filled
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <div class="subtitle-2 font-weight-bold pb-3">
                                               <v-icon>mdi-account</v-icon>
                                                REGISTRO DE SOCIOS
                                            </div>
                                        </v-col>
                                        <v-col cols="12" v-if="integrantes.length>0">
                                              <div v-for="(integrante, index) in integrantes" :key="index" class="layout">
                                                <v-text-field
                                                    v-model="integrante.nombreAbogado"
                                                    class="required"
                                                    :rules="getRules('required')"
                                                    label="Nombre Abogado"
                                                    filled
                                                    readonly
                                                ></v-text-field>
                                                <v-text-field
                                                    v-model="integrante.cargo"
                                                    class="required px-2"
                                                    :rules="getRules('required')"
                                                    label="Cargo"
                                                    filled
                                                ></v-text-field>
                                                <v-btn v-if="!integrante.esRepresentante" text icon @click="eliminarFila(index)" color="error">
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on" v-bind="attrs" slot="activator">mdi-delete</v-icon>
                                                        </template>
                                                        <span>Eliminar</span>
                                                    </v-tooltip>
                                                </v-btn>
                                            </div>
                                            <Buscar @seleccion="abogadoSeleccionado" class="my-5">
                                                <template slot="texto-busqueda">Añadir Socio</template>
                                            </Buscar>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions  class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                    Cancelar
                                </v-btn>
                                    <v-btn
                                    color="primary"
                                    :loading="loading"
                                    @click="validarFormulario()"
                                >
                                    SIGUIENTE
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :complete="paso > 2" step="2">
                        REGISTRO DE DOCUMENTOS 
                    </v-stepper-step>
                    <v-stepper-content step="2" class="py-0">
                        <v-card class="elevation-0">
                            <v-card-text class="pa-0">
                                <v-form v-model="valid" ref="formularioAdjuntos" lazy-validation>
                                    <v-row  no-gutters>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="4"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <p> Debes ingresar tus documentos escaneados en un solo archivo en formato PDF con el siguiente orden: </p>
                                            <ul class="pb-5">
                                                <li>
                                                    Acta o escritura de constitución que describa la estructura interna
                                                </li>
                                            </ul>
                                            <v-file-input
                                                label="Acta o Escritura de Constitución "
                                                class="required pt-0 mt-0"
                                                @change="onFileChangeDocumentos('constitucion')"
                                                v-model="documentos.constitucion.adjunto"
                                                :rules="rules"
                                                accept="application/pdf"
                                                prepend-icon="mdi-paperclip"
                                                show-size
                                                filled
                                                counter
                                                outlined
                                            >
                                                <template v-slot:selection="{ text }">
                                                    <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                    >
                                                        {{ text }}
                                                    </v-chip>
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="8"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <div id="preview_documento_constitucion"></div>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="4"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <ul class="pb-5">
                                                <li>
                                                    Reglamentos y procedimientos internos
                                                </li>
                                            </ul>
                                            
                                            <v-file-input
                                                label="Reglamentos y Procedimientos"
                                                class="required pt-0 mt-0"
                                                @change="onFileChangeDocumentos('reglamento')"
                                                v-model="documentos.reglamento.adjunto"
                                                :rules="rules"
                                                accept="application/pdf"
                                                prepend-icon="mdi-paperclip"
                                                show-size
                                                filled
                                                counter
                                                outlined
                                            >
                                                <template v-slot:selection="{ text }">
                                                    <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                    >
                                                        {{ text }}
                                                    </v-chip>
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="8"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <div id="preview_documento_reglamento"></div>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="4"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <ul class="pb-5">
                                                <li>
                                                    Documento que acredite la designacion del representante legal
                                                </li>
                                            </ul>
                                            
                                            <v-file-input
                                                label="Documento Representante Legal"
                                                class="required pt-0 mt-0"
                                                 @change="onFileChangeDocumentos('representante')"
                                                v-model="documentos.representante.adjunto"
                                                :rules="rules"
                                                accept="application/pdf"
                                                prepend-icon="mdi-paperclip"
                                                show-size
                                                filled
                                                counter
                                                outlined
                                            >
                                                <template v-slot:selection="{ text }">
                                                    <v-chip
                                                        color="primary accent-4"
                                                        dark
                                                        label
                                                        small
                                                    >
                                                        {{ text }}
                                                    </v-chip>
                                                </template>
                                            </v-file-input>
                                        </v-col>
                                        <v-col 
                                            cols="12"
                                            lg="6"
                                            md="8"
                                            xs="12"
                                            class="px-1"
                                        >
                                            <div id="preview_documento_representante"></div>
                                        </v-col>
                                    </v-row>
                                 </v-form>                        
                            </v-card-text>
                            <v-card-actions  class="pt-0">
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                    Cancelar
                                </v-btn>
                                 <v-btn text color="primary" @click="paso--">
                                    Atras
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    :loading="loading"
                                    @click="validarFormulario()"
                                >
                                    SIGUIENTE
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>

                    <v-stepper-step :complete="paso > 3" step="3">
                        FIRMA DIGITAL
                    </v-stepper-step>

                    <v-stepper-content step="3" class="py-0">
                        <v-card>
                            <v-card-text class="pa-0">
                                <div>Debes Aprobar Digitalmente tu formulario de solicitud de registro</div>
                                <div id="previewDocumentoMergeado" ></div>
                            </v-card-text>
                            <v-overlay :value="overlay" :absolute="true" color="primary">
                                <v-progress-circular class="pr-5" indeterminate size="50" color="orange"></v-progress-circular>
                                {{mensaje}}
                            </v-overlay>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="error" @click="consulta=true">
                                        Cancelar
                                    </v-btn>
                                    <v-btn text color="secondary" @click="paso=2">
                                        atras
                                    </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="aprobarDocumento"
                                    :loading="loading"
                                >
                                    APROBAR y GUARDAR SOLICITUD
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-stepper-content>
                </v-stepper>
                <confirmacion
                    :pregunta="consulta"
                    :consulta="pregunta"
                    :loading="loadingConfirmacion"
                    @si="aceptacionConfirmacion"
                    @no="cancelarConfirmacion"
                />
            </v-card-text>
            <v-card-text v-else>
                <v-row>
                    <v-col class="text-center my-5">
                        <v-progress-circular indeterminate color="primary"/>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import PDFObject from "@/../paquetes_externos/pdfobject.min";
import mixin_rules from "@/mixins/rules"
import mixinSociedad from "@/mixins/sociedadCivil"

import mixinB64toBlob from "@/mixins/b64toblob"
import { mapState } from "vuex";
export default {
    props:{
        dialog:{
            type: Boolean,
            default: false
        },
        value:{
            type: Object,
            default:()=>{
                return {
                    idSociedadCivil:-1
                }
            }
        }
    },
    components:{
        'confirmacion': ()=>import("@/common/util/Confirmacion"),
        Buscar:()=>import("@/components/Busqueda")
        // 'firma-documento': ()=>import("@/components/FirmaDocumento"),
        // 'codigo-pago':()=>import("@/components/Cpt")
    },
    mixins:[ mixin_rules, mixinB64toBlob, mixinSociedad ],
    data(){
        return{
            titulo: "Registro de Nueva Sociedad Civil",
            requisitos:[
                {
                    icon:'mdi-file-account',
                    titulo:'Nombres, apellidos y respectivos cargos de los socios',
                    subtitulo:null
                },
                {
                    icon:'mdi-file-pdf-box',
                    titulo:'Acta o escritura de constitución que describa la estructura interna en formato PDF',
                    subtitulo:'El archivo no debe ser mayor a 5 MB'
                },
                {
                    icon:'mdi-file-pdf-box',
                    titulo:'Reglamento y procedimientos internos en formato PDF',
                    subtitulo:'El archivo no debe ser mayor a 5 MB'
                },
                {
                    icon:'mdi-file-pdf-box',
                    titulo:'Documento que acredite la designación del representante legal en formato PDF',
                    subtitulo:'El archivo no debe ser mayor a 5 MB'
                }
            ],
            paso:0,
            sociedad:{},
            integrantes:[],
            adjuntos:[],
            documentos:{
                constitucion:{
                    url:null,
                    adjunto:null
                },
                reglamento:{
                    url:null,
                    adjunto:null
                },
                representante:{
                    url:null,
                    adjunto:null
                }
            },
            urlDocumentos:null,
            documentoCompleto:null,
            itemsTipo:[
                {value: 1,text:'POR VENCIMIENTO O CADUCIDAD'},
                {value: 2,text:'POR DETERIORO'},
                {value: 3,text:'POR PERDIDA'}
            ],
            valid:true,
            nota:null,
            notaFirmada:null,
            loading:false,
            pregunta:'¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados',
            consulta:false, 
            token:null,
            overlay: false,
            mensaje:'',
            dialogFirma:false,
            dialogAprobacion:false,
            loadingConfirmacion:false,
            rules:[
                v=>!!v||'Este Campo es Obligatorio',
                v => !v || v.size < 6000000 || 'El archivo no debe pesar mas de 6 MB!'
            ],
            consultado:false
        }
    },
    computed: {
        ...mapState({ 
            registro: state => state.abogado.registro,
            persona: state=>state.abogado.persona,
            nombreCompleto() {
                return this.$store.getters['abogado/getNombreCompleto'];
            }
        })
    },
    async mounted(){
        if(this.value.idSociedadCivil){
            this.titulo = 'Modificación de Nueva Sociedad Civil'
            const {sociedad,integrantes} = await this.getInfoSociedaCivil(this.value.idSociedadCivil)
            if(sociedad){
                this.sociedad = {
                    departamento:sociedad.departamento,
                    nombreSociedad:sociedad.nombreSociedad,
                    direccion:sociedad.direccion
                }
            }
            if(integrantes.length>0){
                for (const i of integrantes) {
                    this.integrantes.push({
                        esRepresentante:i.esRepresentante,
                        cargo:i.cargo,
                        activo:i.activo,
                        nombreAbogado:i.nombreAbogado,
                        abogado:i.abogado
                    })
                }
            }
            
        }else{
            this.integrantes.push(
                {
                    abogado:this.registro.id,
                    nombreAbogado:this.nombreCompleto,
                    cargo:'REPRESENTANTE LEGAL',
                    activo: true,
                    esRepresentante:true
                }
            )
        }   
        this.consultado=true            
    },
    methods:{
        cancelarRegistro(){
            this.$emit("cancelar")
        },
        validarFormulario(){
            if(this.paso == 1){
                if(this.$refs.formulario.validate()){
                    if(this.integrantes.length<2){
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: "Error en Registro de Sociedad",
                            text:
                            "Debe agregar al menos dos abogados para registrar la sociedad civil"
                        })
                    }else{
                        this.paso++
                    }
                    return
                }
            }
            if(this.paso == 2){
                if(this.$refs.formularioAdjuntos.validate()){
                    this.loading = true
                    let formData = new FormData();
                    formData.append("sociedad", JSON.stringify(this.sociedad))
                    formData.append("integrantes",JSON.stringify( this.integrantes))
                    formData.append("adjuntos",JSON.stringify(this.adjuntos))                   
                
                    this.$http.post(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/nota-solicitud`, 
                    formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then(response=>{
                        this.documentoCompleto=this.base64toBlob(response.data.data.pdf,'application/pdf')
                        const url =window.URL.createObjectURL(this.documentoCompleto)
                        setTimeout(() => {
                        this.showDocumento(
                            url,'#previewDocumentoMergeado',false);
                        }, 100);
                        this.loading=false,
                        this.paso=3
                    }).catch(error=>{
                        console.log('------------------------------------');
                        console.log(error);
                        console.log('------------------------------------');
                        this.loading=false
                    })
                }
            }
        },
        aprobarDocumento(){
            this.mensaje='Aprobando Documento Con Ciudadanía Digital'
            this.overlay=true
            this.dialogAprobacion=true
            this.pregunta='¿Está seguro de aprobar el documento con Ciudadanía Digital?. en caso de que acepte no podra realizar ninguna Modificación'
            this.consulta=true
        },
        aceptacionConfirmacion(){
            if(this.dialogAprobacion){
                this.registroSolicitudSociedadCivil()
            }else{
                this.cancelarRegistro()
            }
        },
        cancelarConfirmacion(){
            this.consulta = false
            this.pregunta = '¿Está seguro de cancelar la solicitud?. se eliminaran los datos registrados'
            this.dialogAprobacion = false
            this.overlay=false
        },
        registroSolicitudSociedadCivil(){
            this.mensaje='Registrando Solicitud Espere un momento ...'
            this.overlay=true
            this.dialogFirma=false
            this.notaFirmada=null
            this.loading = true
            let formData = new FormData();
            formData.append("formulario", this.documentoCompleto)
            formData.append("sociedad",JSON.stringify(this.sociedad))
            formData.append("integrantes",JSON.stringify(this.integrantes))
            formData.append("adjuntos",JSON.stringify(this.adjuntos))
            if(this.dialogAprobacion){
                formData.append("aprobacionCiudadania",true)
                formData.append("rutaCliente",this.$route.fullPath)
            }
            this.loadingConfirmacion = true
            let url= `${this.$apiUrl}abogado-ciudadano/sociedad-civil`
            if(this.value.id){
                url = `${this.$apiUrl}abogado-ciudadano/sociedad-civil/${this.value.idSociedadCivil}/modificacion`
            }
            this.$http.post(url,
                formData, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }   
            }).then(response=>{
                this.loading = false
                const data=response.data.data
                this.paso=4
                this.overlay = false
                 this.loadingConfirmacion = false
                if(data.aprobacionCiudadania){
                    if(data.aprobacionCiudadania.linkAprobacion) {
                    this.$notify({
                            group:'foo',
                            type:'success',
                            title:'APROBACION DOCUMENTOS',
                            text:'Debe aprobar los documentos de su solicitud con Ciudadanía Digital'
                        })
                    window.location.href = data.aprobacionCiudadania.linkAprobacion
                    }
                }
                this.consulta = false  
            }).catch(error=>{
                 this.loadingConfirmacion = false
                this.loading = false
                console.log(error)
            })
        },
        showDocumento(pdf,id, base64) {
            const options = {
                height: "500px",
                pdfOpenParams: {
                pagemode: "thumbs",
                navpanes: 0,
                toolbar: 1,
                statusbar: 1,
                view: "FitH"
                }
            };
            if(base64){
                pdf='data:application/pdf;base64,'+pdf
            }
            PDFObject.embed(pdf, id, options);
        },
        continuar(){
            if(this.paso === 0){
                this.paso ++
                return
            }
            if(this.paso === 1){
                if(this.$refs.formularioVerificacion.validate()){
                    this.paso ++
                    return
                }
            }
        },
        abogadoSeleccionado(item) {
            var integrante = {
                abogado: item.id,
                nombreAbogado:item.nombreCompleto,
                cargo: null,
                activo: true,
                esRepresentante:false
            };
            if (
                this.integrantes.filter(e => e.abogado === item.id).length === 0
            ) {
                this.integrantes.push(integrante);
            } else {
                this.$notify({
                group: "foo",
                type: "error",
                title: "Error en Seleccion",
                text: "El abogado Ya se encuentra en la Sociedad Civil"
                });
            }
        },
        eliminarFila(index) {
            this.integrantes.splice(index, 1);
        },
        async onFileChangeDocumentos(tipo){
            if(!this.documentos[tipo].adjunto){
                return this.documentos[tipo].url=null
            }
            const tipos={
                constitucion:'CONSTITUCION',
                reglamento:'REGLAMENTO',
                representante:'REPRESENTANTE LEGAL'
            }
            const form = new FormData()
            form.append('documento',this.documentos[tipo].adjunto)
            form.append('tipo',tipos[tipo])
            const respuesta = await this.$http.post(`${this.$apiUrl}abogado-ciudadano/sociedades-civiles/documento`,form).catch(error=>{
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error subida de archivo",
                    text:error
                })
                console.log('------------------------------------');
                console.log(error);
                console.log('------------------------------------');
                return
            })
            if(respuesta){
                this.documentos[tipo].url = URL.createObjectURL(this.documentos[tipo].adjunto);
                this.adjuntos.push(respuesta.data.data)
                setTimeout(() => {
                    this.showDocumento( this.documentos[tipo].url,'#preview_documento_'+tipo,false);
                }, 100);   
            }else{
                this.documentos[tipo]={
                    url:null,
                    adjunto:null
                }
            }
        },
    }
}
</script>
<style>
.required label::after {
  content: " *";
  color: red;
}
.v-stepper__step--active + .auto-height .v-stepper__wrapper {
    height: auto !important;
}
</style>